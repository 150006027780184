import "./check-balance.scss";
import React from "react";

const CheckBalance = () => {
	const iframe = () => {
		return {
			__html:
				'<iframe src="https://public-transport.tatar/tkstatus/" frameBorder="no" class="check-balance__ext-frame"></iframe>'
		};
	};

	return (
		<div className="grid-container">
			<section className="check-balance">
				<div className="row">
					<div className="col-xs-12 col-sm-offset-1 col-sm-10 col-md-offset-2 col-md-8">
						<h2 className="check-balance__header">Проверка баланса</h2>
						<div dangerouslySetInnerHTML={iframe()} />
					</div>
					<div className="hidden-xs col-sm-1 col-md2" />
				</div>
			</section>
		</div>
	);
};

export default CheckBalance;
